import React, { Component } from 'react';
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';



class App extends Component {
  state = {
    flipped1: false,
  }

  handleFlipping = id => () => {
    const cardId = `flipped${id}`;
    this.setState({ [cardId]: !this.state[cardId] });
  }

  componentDidMount() {

    $(".navbar-nav").append("<li class='magic-line'></li>");

    window.addEventListener("scroll", this.resizeHeaderOnScroll);

  }

  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      headerEl = document.getElementById("navbar");

    if (distanceY > shrinkOn) {
      headerEl.classList.remove("container");
      headerEl.classList.add("smaller");
      // headerEl.classList.add("shadow");
    } else {
      headerEl.classList.add("container");
      headerEl.classList.remove("smaller");
      // headerEl.classList.remove("shadow");
    }
  }

  render() {


    return (
      <div className="App">
        <header>
          <Navbar expand="lg" variant="light" sticky="top" className="container" id="navbar">
            <Navbar.Brand href="/"><img src="images/cradxlogo.gif" className="app-logo" alt=""></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link className="n1" href="#services">Services</Nav.Link>
                <Nav.Link className="n2" href="#who">Who We are</Nav.Link>
                <Nav.Link className="n3" href="#technologies">Technologies</Nav.Link>
                <Nav.Link className="n4" href="#team">Team</Nav.Link>
                <Nav.Link className="n5" href="#contact">Contact</Nav.Link>
                <Nav.Link className="n6" href="https://blog.cradx.com" target="_self">Blog</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

        </header>
        <section className="banner" id="home">
          <div id="hero-wrap" className="container">
            <span>We're a top-notch <br />software development <br /><span className="com">company</span></span>
            <br />
            <a href="#contact"><Button variant="light" className="mt-5 getStartedbtn">Get Started</Button></a>
          </div>
          <div class="arrow-down"></div>
          <div class="arrow-down"></div>
          <div class="arrow-down"></div>
          <div class="lds-ripple">
            <div></div><div></div></div>
        </section>

        {/* <!-- Our services section --> */}
        <section id="services">
          <div id="title">

            <h1>Services</h1>
            <img className="sline" src="images/line.svg" alt="" />
            <Container className="pt-5 pb-4">
              <Row className="d-flex">
                <Col md={4}>
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div class="flip-card-front">

                        <Image src="images/code.svg" />
                        <div className="pt-2">
                          <h3 className="mb">Software Development</h3>
                        </div>
                      </div>
                      {/* <div classname="flip-card-back">
                        With the use of latest technology, our expert developers deliever the best
                        products when it comes to developing software to tailored your specifications
                        </div> */}

                      {/* Ranging from desktop experience to the web. Whether you
                          require a desktop app, CMS based website or a web app, our team is ever ready to get the job done
                          for you */}
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <Image src="images/mobile.svg" />
                  <div className="pt-2">
                    <h3 className="mb">Mobile Development</h3>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <Image src="images/consult.svg" />
                  <div className="pt-2">
                    <h3 className="mb">IT Consulting</h3>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex pt-3">
                <Col xs={6} md={4}>
                  <Image src="images/ui.svg" />
                  <div className="pt-2">
                    <h3 className="mb">UI/UX Design</h3>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <Image src="images/testing.svg" />
                  <div className="pt-2">
                    <h3 className="mb">QA & Testing</h3>
                  </div>
                </Col>
                <Col md={4}>
                  <Image src="images/products.svg" />
                  <div className="pt-2">
                    <h3 className="mb">Product Design</h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        {/* <!-- who we are section --> */}
        <section id="who">
          <div id="title">
            <div className="port-down">
              <Container className="pt-5 pb-5">
                <Row className="d-flex">
                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
                    <Image className="d-flex self-stretch" src="images/wwa.jpg" height="100%" width="100%" thumbnail />
                  </Col>
                  <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2}}>
                    <div className="ml-5 mt-5">
                      <h3 className="p-t">Cradx is a cross-industry technology company that makes imaginations realistic, when you can imagine, we can build.
            We're created to create products that actually work.</h3>
                    </div>
                  </Col>
                </Row>
              </Container>

            </div>
          </div>

        </section>
        {/* <!-- Technogies we use section --> */}
        <section id="technologies">
          <div id="title">
            <h1>TECHNOLOGIES</h1>
            <img alt="" className="sline" src="images/line.svg"></img>
          </div>
          <Container className="pt-5 pb-5">
            <Row className="d-flex">
              <Col md={6}>
                <img className="img d-flex align-self-stretch" src="images/techie.svg" alt="" height="100%" width="100%" />
              </Col>
              <Col className="pt-5" md={6}>
                <Row>
                  <Col xs={3} md={3}>
                    <img className="img" src="images/python.svg" alt="" height="100%" width="50%" />
                  </Col>
                  <Col xs={3} md={3}>
                    <img className="img" src="images/flutter.svg" alt="" height="100%" width="50%" />
                  </Col>
                  <Col xs={3} md={3}>
                    <img className="img" src="images/csharp.svg" alt="" height="100%" width="50%" />
                  </Col>
                  <Col xs={3} md={3}>
                    <img className="img" src="images/kotlin.svg" alt="" height="100%" width="50%" />
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col xs={3} md={3}>
                    <img className="img" src="images/angular.svg" alt="" height="100%" width="50%" />
                  </Col>
                  <Col xs={3} md={3}>
                    <img className="img" src="images/java.svg" alt="" height="100%" width="50%" />
                  </Col>
                  <Col xs={3} md={3}>
                    <img className="img" src="images/django.svg" alt="" height="100%" width="50%" />
                  </Col>
                  <Col xs={3} md={2}>
                    <img className="img" src="images/react.svg" alt="" height="100%" width="50%" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <!-- Team section --> */}
        <section id="team">
          <div id="title">
            <h1>Our Team</h1>
            <img alt="" className="sline" src="images/line.svg" />
          </div>
          <Container className="pt-5 pb-5 align-center">
            <Row className="d-flex">
              <Col xs={6} md={3}>
                <Image className="service-img" src="images/wise.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Wise Duho</h3>
                <p className="muted mb-3 mb-md-0">Senior Software Engineer</p>
              </Col>
              <Col xs={6} md={3}>
                <Image className="service-img" src="images/emma.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Emmanuel Mensah</h3>
                <p className="muted mb-3 mb-md-0">Senior Software Engineer</p>
              </Col>
               <Col xs={6} md={3}>
                <Image className="service-img" src="images/bright.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Bright Amegbor</h3>
                <p className="muted mb-3 mb-md-0">Senior Software Engineer</p>
              </Col>
               <Col xs={6} md={3}>
                <Image className="service-img" src="images/lucie.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Lucienne Awoudi</h3>
                <p className="muted mb-3 mb-md-0">Associate Software Engineer</p>
              </Col>
            </Row>
            <Row className="d-flex pt-4">
             
              <Col xs={6} md={3}>
                <Image className="service-img" src="images/mawuli.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Mawuli Stephen</h3>
                <p className="muted mb-3 mb-md-0">Marketing Manager</p>
              </Col>
              <Col xs={6} md={3}>
                <Image className="service-img" src="images/joel.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Joel Amannor</h3>
                <p className="muted mb-3 mb-md-0">Senior Software Engineer</p>
              </Col>
               <Col xs={6} md={3}>
                <Image className="service-img" src="images/golda.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Golda Kporfor</h3>
                <p className="muted mb-3 mb-md-0">Accountant</p>
              </Col>
              <Col xs={6} md={3}>
                <Image className="service-img" src="images/elorm.jpg" roundedCircle thumbnail />
                <h3 className="t-text">Elorm Ashiagbor</h3>
                <p className="muted mb-3 mb-md-0">Legal Counsel</p>
              </Col>
            </Row>
            <Row className="d-flex pt-4">
             
              <Col xs={6} md={3}>
                <Image className="service-img" src="images/avatar.gif" roundedCircle thumbnail />
                <h3 className="t-text">You</h3>
                <p className="muted mb-3 mb-md-0">Awesome, skilled & curious</p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <!-- Contact section --> */}
        <section id="contact">
          <div id="title">
            <h1>GET IN TOUCH</h1>
            <img alt="" className="sline" src="images/line.svg"></img>
          </div>
          <Container>
            <Row className="d-flex">
              <Col md={6}>
                <img className="img d-flex align-self-stretch" src="images/contact.svg" alt="" height="100%" width="100%" />
              </Col>
              <Col md={6}>
                <div className="contact-section text-left">
                  <h5>How can we help you? We'd love to hear from you:</h5>
                  <h6><a href="mailto:hello@cradx.com">hello@cradx.com</a></h6>
                  <h6><a href="tel:+233240748375">+233240748375</a></h6>
                  <li>
                    <a className="flaticon-twitter" href="https://twitter.com/cradxlab" target="_blank" rel="noopener noreferrer"></a>
                    <a className="flaticon-linkedin" href="https://linkedin.com/company/cradx" target="_blank" rel="noopener noreferrer"></a>
                    <a className="flaticon-github-1" href="https://github.com/cradxlab" target="_blank" rel="noopener noreferrer"></a>
                  </li>
                </div>
              </Col>
            </Row>

          </Container>
        </section>
        <footer>
          <p>Copyright &copy; Cradx {(new Date().getFullYear())}. All rights reserved</p>
        </footer>
      </div>
    );
  }
}
export default App;
